
import { defineComponent } from 'vue'
import _ from 'lodash'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import { OverviewData } from '../../../../types/io_page_types'
import {
  compareDateStrings,
  formatNumbers,
  formatDateToYearMonthDay
} from '../../../../utils/ioPageUtils'

export default defineComponent({
  name: 'IOPageOverviewFlight',
  computed: {
    overview (): OverviewData {
      return this.$store.getters.getOverview
    },
    flightTitle (): string {
      if (this.currentFlightRemaining < 0 || !this.billingDataFlightStarted) {
        return 'No current flight'
      }
      return `${this.currentFlightRemaining} days left`
    },
    flightSubtitle (): string {
      if (this.currentFlightRemaining < 0 || !this.billingDataFlightStarted) {
        return ''
      }
      return `Started on ${formatDateToYearMonthDay(this.currentFlightStartDate as Date)}`
    },
    flightEndTooltip (): string {
      return `Flight ends on ${this.overview?.overview?.billing_end}`
    },
    nextFlightTitle (): string {
      if (!isNotNullOrUndefined(this.nextFlightStartDate)) {
        return 'No upcoming flight scheduled'
      }
      return 'Next flight:'
    },
    nextFlightSubtitle (): string {
      if (!isNotNullOrUndefined(this.nextFlightStartDate) || !isNotNullOrUndefined(this.nextFlightEndDate)) {
        return ''
      }
      return `${this.nextFlightStartDate} - ${this.nextFlightEndDate}`
    },
    billingDataFlightStarted (): boolean {
      const today = new Date()
      if (!isNotNullOrUndefined(this.currentFlightStartDate)) {
        return false
      }
      return compareDateStrings(this.currentFlightStartDate.toString(), today.toString()) >= 0
    },
    currentFlightStartDate (): Date {
      return new Date(this.overview?.overview?.billing_start)
    },
    currentFlightRemaining (): number {
      const endDate = new Date(this.overview?.overview?.billing_end)
      const today = new Date()
      // get days difference between today and end date
      return compareDateStrings(today.toString(), endDate.toString())
    },
    nextFlightStartDate (): string {
      if (!this.billingDataFlightStarted) {
        return formatDateToYearMonthDay(this.overview?.overview?.billing_start)
      }
      return formatDateToYearMonthDay(this.overview?.overview?.next_billing_start)
    },
    nextFlightEndDate (): string {
      if (!this.billingDataFlightStarted) {
        return formatDateToYearMonthDay(this.overview?.overview?.billing_end)
      }
      return formatDateToYearMonthDay(this.overview?.overview?.next_billing_end)
    },
    remainingBudgetText (): string {
      return this.remainingBudget && this.remainingBudget >= 0 ? formatNumbers(this.remainingBudget) : 'No remaining budget'
    },
    totalBudgetText (): string {
      return this.totalBudget && this.totalBudget >= 0 ? formatNumbers(this.totalBudget) : 'No set budget'
    },
    totalBudget (): number {
      return this.overview?.budget?.flight_budget
    },
    remainingBudget (): number {
      return _.floor(this.overview?.budget?.flight_budget - this.overview?.budget?.flight_budget_spent)
    }
  }
})
