
import { defineComponent } from 'vue'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import IOForm from '@/components/TableComponents/Form/IOForm.vue'
import { Baseline, DspInstruction, Instruction, IoBrief } from '../../../types/instruction_type'
import ioFormMixin from '@/mixins/ioFormMixin'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import { getNewIoField } from '../../../utils/instructionsUtils'
import { mapMutations } from 'vuex'
import { AutocompleteItem, SurcoucheV2QueryModel } from '../../../types/surcouche_v2_types'
import OutcomesDialog from '@/components/TableComponents/Outcomes/OutcomesDialog.vue'
import { OverviewData } from '../../../types/io_page_types'
import AutocompleteSearchBar from '@/components/Common/AutocompleteSearchBar.vue'

export default defineComponent({
  name: 'IOPageHeader',
  components: { AutocompleteSearchBar, OutcomesDialog, IOForm, CopyClipboardComponent },
  mixins: [ioFormMixin, instructionsCaller],
  props: ['ioId', 'dsp'],
  data () {
    return {
      isHoverMember: false,
      isHoverAdvertiser: false,
      isHoverIo: false,
      ioSearch: '',
      openTab: 'tab-1',
      notes: 0,
      openActivityLogs: false,
      resultSync: null as AutocompleteItem
    }
  },
  async mounted () {
    await this.callApiNotes()
    await this.fetchIoAsSV2()
    this.$store.commit('setSurcoucheV2SearchParamsFilters', { filter: 'dsp', value: this.dsp })
  },
  methods: {
    ...mapMutations(['setErrorMessage', 'editStartUpdate', 'editEndUpdate']),
    getRedirectionRoute (entityType: string) {
      let entityId = this.ioId
      switch (entityType) {
        case 'member':
          entityId = this.memberId
          break
        case 'advertiser':
          entityId = this.advertiserId
          break
        case 'insertionOrder':
          entityId = this.ioId
          break
      }
      return {
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: entityType },
        query: { search: entityId }
      }
    },
    redirectToSurcoucheV2 () {
      this.$emit('back')
    },
    getItem (item: AutocompleteItem) {
      this.resultSync = item
    },
    redirectToEntity (entityType: string, newTab: boolean = false) {
      if (newTab) {
        window.open(this.$router.resolve(this.getRedirectionRoute(entityType)).href, '_blank')
      } else {
        this.$router.push(this.getRedirectionRoute(entityType))
      }
    },
    async callApiNotes () {
      const notesResponse = await this.$apiCaller.getInstructionNotes(this.ioId, this.dsp)
      if (!this.$apiCaller.isResponseError(notesResponse) && notesResponse.data) {
        this.notes = notesResponse.data.length
      } else {
        this.$store.commit('setErrorMessageWithResponse', notesResponse)
      }
    },
    openEdit () {
      this.openTab = 'tab-1'
      this.openActivityLogs = false
      this.editItem()
    },
    openOutcomes () {
      this.openInstruStratDialog(this.ioId)
    },
    openLogs () {
      this.openTab = 'tab-3'
      this.openActivityLogs = true
      this.editItem()
    },
    openNotes () {
      this.openTab = 'tab-4'
      this.openActivityLogs = false
      this.editItem()
    },
    getIOField () {
      return getNewIoField(this.dsp)
    },
    async editItem () {
      this.editStartUpdate()
      let updatedInstruction : Instruction[] = []

      try {
        updatedInstruction = await this.fetchUpdatedInstruction(this.ioId)
      } catch (error) {
        this.editEndUpdate()
        return
      }

      this.$set(this.instructionSortedByIo, this.editedIOIndex, updatedInstruction)
      this.nextTickOpenEditFormV2(updatedInstruction, this.ioId, this.$EDIT)
      this.editEndUpdate()
    },
    async save (acknowledgments : AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      await this.editMultiProcess(acknowledgments, baseline)
      this.close()
    },
    async editMultiProcess (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      const result = await this.prepareEditMultiProcess()
      await this.deleteAndPostRaw([
        ...result.editedInstru,
        ...result.newInstru
      ], acknowledgments, result.toDeleteInstruId, baseline)
    },
    async fetchIoAsSV2 () {
      const filtersQuery: SurcoucheV2QueryModel = {
        search: this.ioId
      }
      this.instructions = await this.fetchNextIo(filtersQuery)
      await this.fetchListIoV2()
    }
  },
  computed: {
    loading (): boolean {
      return this.$store.getters.getLoadingInsertionOrder || this.$store.getters.getLoadingOverview
    },
    item (): IoBrief<DspInstruction> {
      return this.$store.getters.getIoBrief
    },
    overview (): OverviewData {
      return this.$store.getters.getOverview
    },
    updatedDsp (): string {
      if (this.dsp === 'appnexus') {
        return 'xandr'
      }
      return this.dsp
    },
    formTitle (): string {
      return 'edit item'
    },
    keyIoForm () : string {
      return this.editedItem.id
    },
    advertiserId (): string {
      return this.overview?.overview?.advertiser?.external_id || 'Advertiser ID'
    },
    advertiserName (): string {
      return this.overview?.overview?.advertiser?.name || 'Advertiser name'
    },
    memberId (): string {
      return this.overview?.overview?.advertiser?.member?.external_id || 'Member ID'
    },
    memberName (): string {
      return this.overview?.overview?.advertiser?.member?.name || 'Member name'
    },
    ioName (): string {
      return this.overview?.overview?.name || 'IO name'
    }
  },
  watch: {
    resultSync (value: AutocompleteItem) {
      if (value) {
        this.ioSearch = value.external_id
        this.$emit('search', this.ioSearch)
      }
    }
  }
})
