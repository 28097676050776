
import { defineComponent } from 'vue'
import BaseCardDialog from '@/components/Common/BaseCardDialog.vue'
import CheckPreMepCard from '@/components/CheckPreMepComponents/CheckPreMepCard.vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import { CheckPreMepResult, CheckSetupState, DspInstruction, IoBrief } from '../../../../types/instruction_type'
import { OverviewData } from '../../../../types/io_page_types'
const BLOCKING = 'BLOCKING'
const TO_IMPROVE = 'TO IMPROVE'
const GOOD = 'GOOD'

export default defineComponent({
  name: 'IOPageOverviewStatuses',
  components: { CheckPreMepCard, BaseCardDialog },
  data () {
    return {
      dialogCheckPreMep: false
    }
  },
  methods: {
  },
  computed: {
    ioDatastore (): IoBrief<DspInstruction> {
      return this.$store.getters.getIoBrief
    },
    overview (): OverviewData {
      return this.$store.getters.getOverview
    },
    titleCheckPreMep (): string {
      return `
      Check-PreMep result :
      | Io : ${this.ioValue}
      | Dsp : ${this.$route.params.dsp}
    `
    },
    ioValue (): string {
      if (!this.overview?.overview?.external_id) {
        return this.$route.params.external_id
      }
      return `${this.overview.overview.external_id} (${this.overview.overview.name})`
    },
    briefStatus (): string {
      return isNotNullOrUndefined(this.ioDatastore?.status) ? this.ioDatastore?.status : ''
    },
    ioStatus (): string {
      return isNotNullOrUndefined(this.overview?.overview?.status) ? this.overview?.overview?.status : ''
    },
    checkPreMepResult (): CheckPreMepResult {
      return this.overview?.check_pre_mep
    },
    checkPremepStatus (): CheckSetupState {
      return this.overview?.check_pre_mep?.check_setup_state
    },
    checkPreMepIsGood (): boolean {
      return this.checkPremepStatus === 'good'
    },
    checkPreMepBtnLabel (): string {
      if (['blocking_editable', 'blocking_non_editable'].includes(this.checkPremepStatus)) {
        return BLOCKING
      } else if (this.checkPremepStatus === 'non_blocking_editable') {
        return TO_IMPROVE
      } else if (this.checkPremepStatus === 'good') {
        return GOOD
      }
      // default is blocking
      return BLOCKING
    },
    classBtnCheckPreMep (): string {
      if (this.checkPreMepBtnLabel === BLOCKING) {
        return 'blocking'
      } else if (this.checkPreMepBtnLabel === TO_IMPROVE) {
        return 'to-improve'
      }
      return 'good'
    }
  }
})
