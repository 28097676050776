import { render, staticRenderFns } from "./IOPagePerformanceConstraints.vue?vue&type=template&id=0fccafc6&scoped=true"
import script from "./IOPagePerformanceConstraints.vue?vue&type=script&lang=ts"
export * from "./IOPagePerformanceConstraints.vue?vue&type=script&lang=ts"
import style0 from "./IOPagePerformanceConstraints.vue?vue&type=style&index=0&id=0fccafc6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fccafc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VIcon,VRow,VTooltip})
