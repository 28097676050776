import { formatDate, isNotNullOrUndefined } from './commonUtils'

/**
 * Parse a date string to a number representing the number of days since 01/01/1970
 * @param date
 * @return {number} the number of days since 01/01/1970
 */
export function parseDateString (date: string): number {
  if (!isNotNullOrUndefined(date)) {
    return null
  }
  const dateObj = new Date(date)
  const dayInMilliseconds = 1000 * 60 * 60 * 24
  dateObj.setHours(0, 0, 0, 0)
  return Math.floor(dateObj.getTime() / dayInMilliseconds)
}

/**
 * Format a date string to MM/DD
 * @param date the date to format
 * @return {string} the formatted date string
 */
export function formatDateToMonthDay (date: Date): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (isNotNullOrUndefined(date)) {
    return `${(date.getMonth() + 1).toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}/${date.getDate().toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}`
  }
  return null
}

/**
 * Format a date string to YYYY-MM-DD
 * @param date the date to format
 * @return {string} the formatted date string
 */
export function formatDateToYearMonthDay (date: Date): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (isNotNullOrUndefined(date)) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}-${date.getDate().toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}`
  }
  return null
}

/**
 * Format a datetime string to MM-DD-YYYY HH:mm:ss
 * @param date the date to format
 * @return {string} the formatted date string
 */
export function formatDatetime (date: Date): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (isNotNullOrUndefined(date)) {
    return formatDate(date.toISOString())
  }
  return null
}

/**
 * Compare two date strings
 * @param startDateStr the start date string
 * @param endDateStr the end date string
 * @return {number} the number of days between the dates, positive if the end date is after the start date, negative if the end date is before the start date, -1 if one of the dates is null
 */
export function compareDateStrings (startDateStr: string, endDateStr: string): number {
  if (isNotNullOrUndefined(startDateStr) && isNotNullOrUndefined(endDateStr)) {
    return parseDateString(endDateStr) - parseDateString(startDateStr)
  }
  return null
}

/**
 * Check if two date strings are the same date
 * @param dateStr1
 * @param dateStr2
 * @return {boolean} true if the dates are the same, false otherwise
 */
export function areDateStringsSameDate (dateStr1: string, dateStr2: string): boolean {
  return compareDateStrings(dateStr1, dateStr2) === 0
}

/**
 * Format a number to display on the IO page.
 * The number is formatted as follows:
 * - decimals are dotted and not displayed for round numbers (under 0.001 diff with the round number)
 * - display 2 decimals except for numbers smaller than 0.1, where we display 4 decimals
 * - if the number is null, undefined or NaN, return '-'
 * - if the number is smaller than 0.0001, return '0'
 * - if the number is between 1000 and 1000000, display the number with a space every 3 digits
 * - if the number is between 100 thousand and 1 million, display the number of thousands with a 'K' at the end
 * - if the number is between 1 million and 1 billion, display the number of millions with a 'M' at the end
 * - if the number is above 1 billion, display the number of billions with a 'B' at the end
 * @param nb the number to format
 * @param decimals optional number of decimals to display, default number of decimals is 2
 */
export function formatNumbers (nb: number, decimals?: number): string {
  let forceDecimals: boolean = false
  let decimalsToApply = 2
  if (isNotNullOrUndefined(decimals)) {
    forceDecimals = true
    decimalsToApply = decimals
  }
  if (nb === null || nb === undefined || isNaN(nb)) {
    return '-'
  }
  if (Math.abs(nb) < 0.0001) {
    return '0'
  }
  if (Math.abs(nb) > 0.0001 && Math.abs(nb) < 0.1) {
    if (forceDecimals) {
      return (nb).toFixed(decimalsToApply)
    }
    return (nb).toFixed(4)
  }
  if (Math.round(nb) === nb && Math.abs(nb) < 1000) {
    return nb.toString()
  }
  if (Math.abs(nb) < 1000) {
    if (Math.round(nb) === nb) {
      return nb.toString()
    }
    return (nb).toFixed(decimalsToApply)
  }
  if (Math.abs(nb) < 100_000) {
    return (nb).toLocaleString('fr-FR').replace(/,/g, '.').replace(/[\s\u00A0]+/, ' ') // weird whitespace
  }
  if (Math.abs(nb) < 1_000_000) {
    // if the number is within 10 of a round thousand, we don't want to display the decimal
    if (Math.abs(nb) % 1000 < 10) {
      return Math.round(nb / 1000).toString() + 'K'
    }
    return (nb / 1000).toFixed(decimalsToApply) + 'K'
  }
  if (Math.abs(nb) < 1_000_000_000) {
    // if the number is within 10K of a round million, we don't want to display the decimal
    if (Math.abs(nb) % 1_000_000 < 10_000) {
      return Math.round(nb / 1000000).toString() + 'M'
    }
    return (nb / 1_000_000).toFixed(decimalsToApply) + 'M'
  }
  if (Math.abs(nb) >= 1_000_000_000) {
    // if the number is within 10M of a round billion, we don't want to display the decimal
    if (Math.abs(nb) % 1_000_000_000 < 10_000_000) {
      return Math.round(nb / 1_000_000_000).toString() + 'B'
    }
    return (nb / 1_000_000_000).toFixed(decimalsToApply) + 'B'
  }
}
