
import { defineComponent, PropType } from 'vue'
import { $APPNEXUS, $BEESWAX, $DBM, $META, $THETRADEDESK, $YOUTUBE } from '../../../../config/dspConfig'
import {
  tooltipFunction, tooltipFunctionTarget,
  unknownDemographicConf
} from '@/components/TableComponents/Line/Fragments/DSPOverwriting/dspOverwritingUtils'
import _ from 'lodash'
import { DspOverwritingItem, DspOverwritingValue } from '../../../../types/surcouche_v2_types'
import { DspInstruction, InstructionAppnexus, InstructionDsp, IoBrief } from '../../../../types/instruction_type'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import BaseDSPOverwriting from '@/components/TableComponents/Line/Fragments/DSPOverwriting/BaseDSPOverwriting.vue'
import { overwriteData } from '@/components/TableComponents/Line/Fragments/DSPOverwriting/dspOverwritingConstants'

export default defineComponent({
  name: 'IOPageOverviewOverwrites',
  components: { BaseDSPOverwriting },
  props: {
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      ioId: this.$route.params.external_id,
      priorityOverwriteValues: {
        [$APPNEXUS]: ['overwrite_frequency', 'remove_daily_budget_campaigns_only', 'force_li_daily_pacing_asap', 'remove_min_viz'],
        [$DBM]: ['overwrite_frequency', 'overwrite_daily_budget_li', 'force_pacing_asap_li', 'keep_min_viz'],
        [$THETRADEDESK]: ['overwrite_frequency', 'overwrite_daily_budget_money_adgroup', 'overwrite_daily_budget_impressions_adgroup', 'overwrite_viewability'],
        [$YOUTUBE]: ['overwrite_frequency', 'overwrite_frequency_target', 'overwrite_bid_adjustments_on_device', 'allow_switch_io_pacing'],
        [$BEESWAX]: ['overwrite_frequency'],
        [$META]: ['overwrite_campaign_budget']
      }
    }
  },
  methods: {
    isOn (item: DspOverwritingValue) {
      if (isNotNullOrUndefined(item.trueValue)) {
        return item.value === item.trueValue
      }
      return item.value
    }
  },
  computed: {
    item (): IoBrief<DspInstruction> {
      return this.$store.getters.getIoBrief
    },
    overwriteData (): DspOverwritingItem[] {
      return overwriteData[this.dsp].map((item: DspOverwritingItem) => {
        return {
          ...item,
          label: item.label.charAt(0).toUpperCase() + item.label.slice(1)
        }
      })
    },
    priorityOverwriteData (): DspOverwritingItem[] {
      if (!isNotNullOrUndefined(this.dsp)) {
        return []
      }
      return this.overwriteData.filter((item: DspOverwritingItem) => {
        return this.priorityOverwriteValues[this.dsp].includes(item.value)
      })
    },
    nonPriorityOverwriteData (): DspOverwritingItem[] {
      if (!isNotNullOrUndefined(this.dsp)) {
        return []
      }
      return this.overwriteData.filter((item: DspOverwritingItem) => {
        return !this.priorityOverwriteValues[this.dsp].includes(item.value)
      })
    },
    leftColumnOverwrites (): Record<InstructionDsp, DspOverwritingItem[]> {
      let prio = _.cloneDeep(this.priorityOverwriteData)
      let nonPrio = _.cloneDeep(this.nonPriorityOverwriteData)
      while (nonPrio.length > prio.length) {
        prio.push(nonPrio[0])
        nonPrio.shift()
      }
      let overwrites = _.cloneDeep(this.overwriteData)
      let leftOverwrites = overwrites.filter((item: DspOverwritingItem) => {
        return prio.map((item: DspOverwritingItem) => item.label).includes(item.label)
      })
      return {
        [this.dsp]: leftOverwrites
      } as Record<InstructionDsp, DspOverwritingItem[]>
    },
    rightColumnOverwrites (): Record<InstructionDsp, DspOverwritingItem[]> {
      // filter all the non priority Overwrite data where the value is not in an item on the left column
      const rightOverwrites = this.overwriteData.filter((item: DspOverwritingItem) => {
        return !this.leftColumnOverwrites[this.dsp].map((item: DspOverwritingItem) => item.label).includes(item.label)
      })
      return {
        [this.dsp]: rightOverwrites
      } as Record<InstructionDsp, DspOverwritingItem[]>
    }
  }
})
