
import { defineComponent, PropType } from 'vue'
import { formatNumbers } from '../../../../utils/ioPageUtils'
import { KpiInterfaceWithType } from '../../../../types/io_page_types'

export default defineComponent({
  name: 'IOPagePerformanceImpressionsAndCurrency',
  props: {
    kpiFromPeriod: {
      type: Object as PropType<KpiInterfaceWithType>,
      required: true
    }
  },
  methods: {
    formatNumbers
  },
  computed: {
    impressions (): string {
      return this.formatNumbers(this.kpiFromPeriod?.impressions)
    },
    currency (): string {
      return this.formatNumbers(this.kpiFromPeriod?.spent)
    }
  }
})
