
import { defineComponent, PropType } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import { formatNumbers } from '../../../../utils/ioPageUtils'
import { DspInstruction, IoBrief } from '../../../../types/instruction_type'
import { PerformanceData, KpiInterfaceWithType, PerformancePeriod } from '../../../../types/io_page_types'

export default defineComponent({
  name: 'IOPagePerformanceKpiToOptimize',
  props: {
    performance: {
      type: Object as PropType<PerformanceData>,
      required: true
    },
    ioBrief: {
      type: Object as PropType<IoBrief<DspInstruction>>,
      required: true
    },
    kpiFromPeriod: {
      type: Object as PropType<KpiInterfaceWithType>,
      required: true
    },
    kpiFromPreviousPeriod: {
      type: Object as PropType<KpiInterfaceWithType>,
      required: true
    }
  },
  methods: {
    formatNumbers,
    getClassMajorInfo (value: string): string {
      return value?.length > 14 ? 'major-info-sm' : 'major-info'
    }
  },
  computed: {
    kpiToOptimize (): string {
      return this.kpiFromPeriod?.kpi_to_optimize || this.ioBrief?.KPI_to_optimize || '-'
    },
    complementaryKpiName (): string {
      return this.kpiFromPeriod?.complementary_kpi_display_name ? this.kpiFromPeriod.complementary_kpi_display_name : '-'
    },
    kpiToOptimizeValue (): string {
      return this.kpiFromPeriod?.kpi_to_optimize_value ? this.formatNumbers(this.kpiFromPeriod.kpi_to_optimize_value) : '-'
    },
    displayKpiCompare (): boolean {
      return this.kpiToOptimizeValue !== '-' && isNotNullOrUndefined(this.kpiFromPreviousPeriod?.kpi_to_optimize_value)
    },
    kpiCompareIcon (): string {
      const currentValue = this.kpiFromPeriod?.kpi_to_optimize_value
      const prevValue = this.kpiFromPreviousPeriod?.kpi_to_optimize_value
      return prevValue < currentValue ? 'arrow_drop_up' : prevValue > currentValue ? 'arrow_drop_down' : 'remove'
    },
    kpiComparedToPrevious (): string {
      return (this.formatNumbers(((this.kpiFromPeriod?.kpi_to_optimize_value - this.kpiFromPreviousPeriod?.kpi_to_optimize_value) / this.kpiFromPreviousPeriod?.kpi_to_optimize_value) * 100)) + '%'
    },
    displayTarget (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.obj_watcher?.KPI_to_optimize?.value)
    },
    targetValue (): string {
      return formatNumbers(this.ioBrief?.obj_watcher?.KPI_to_optimize?.value)
    },
    displayMaxA (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.max_A)
    },
    maxAValue (): string {
      return formatNumbers(this.ioBrief?.constraints_io?.max_A)
    },
    displayMaxCutoff (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.max_cutoff)
    },
    maxCutOffValue (): string {
      return formatNumbers(this.ioBrief?.constraints_io?.max_cutoff)
    },
    fontSize (): string {
      return (this.kpiToOptimize.length > 14 || this.complementaryKpiName.length > 14) ? 'major-info-sm' : 'major-info'
    }
  }
})
