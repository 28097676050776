import { apiCaller, Vue } from '../index'
import { CommitStateType, IState } from '../../types/surcouche_v2_types'
import {
  OverviewData,
  PerformanceData,
  KpiInterfaceWithType,
  PerformancePeriod
} from '../../types/io_page_types'
import { DspInstruction, IoBrief } from '../../types/instruction_type'

const state = {
  loadingInsertionOrder: false,
  loading: false,
  insertionOrder: {} as IoBrief<DspInstruction>,
  overview: {} as OverviewData,
  performance: {} as OverviewData,
  loadingOverview: false,
  loadingPerformance: false,
  performancePeriod: 'day' as PerformancePeriod
}

const actions = {

  async getApiInsertionOrder ({ commit, state }: CommitStateType, args: {dsp: string, search: string}): Promise<void> {
    commit('setLoadingInsertionOrder', true)
    const resInstructions = await apiCaller.getInstructions(args)
    if (apiCaller.isResponseError(resInstructions)) {
      console.warn('Error when calling instruction')
      commit('setErrorMessageWithResponse', resInstructions)
      commit('setInsertionOrder', {})
    } else if (resInstructions.data.length === 0) {
      console.warn('No instructions found in datastore')
      commit('setErrorMessageWithResponse', 'No instructions found in datastore for this IO')
    } else {
      commit('setInsertionOrder', resInstructions.data[0] as IoBrief<DspInstruction>)
    }
    commit('setLoadingInsertionOrder', false)
  },

  async getApiOverview ({ commit, state }: CommitStateType, args: {dsp: string, search: string}): Promise<void> {
    commit('setLoadingOverview', true)
    const resOverview = await apiCaller.getIoPageOverview(args.dsp, args.search)
    if (apiCaller.isResponseError(resOverview)) {
      console.warn('Error when getting overview info')
      commit('setOverview', {})
    } else {
      commit('setOverview', resOverview.data as OverviewData)
    }
    commit('setLoadingOverview', false)
  },

  async getApiPerformance ({ commit, state }: CommitStateType, args: {dsp: string, search: string}): Promise<void> {
    commit('setLoadingPerformance', true)
    const resPerformance = await apiCaller.getIoPagePerformance(args.dsp, args.search)
    if (apiCaller.isResponseError(resPerformance)) {
      console.warn('Error when getting performance info')
      commit('setErrorMessageWithResponse', resPerformance)
      commit('setPerformance', {})
    } else {
      commit('setPerformance', resPerformance.data as PerformanceData)
    }
    commit('setLoadingPerformance', false)
  }

}

const mutations = {
  setLoadingInsertionOrder (state: IState, load: boolean): void {
    Vue.set(state, 'loadingInsertionOrder', load)
  },
  setInsertionOrder (state: IState, data: IoBrief<DspInstruction>): void {
    Vue.set(state, 'insertionOrder', data)
  },
  setOverview (state: IState, data: OverviewData): void {
    Vue.set(state, 'overview', data)
  },
  setPerformance (state: IState, data: PerformanceData): void {
    Vue.set(state, 'performance', data)
  },
  setLoadingOverview (state: IState, load: boolean): void {
    Vue.set(state, 'loadingOverview', load)
  },
  setLoadingPerformance (state: IState, load: boolean): void {
    Vue.set(state, 'loadingPerformance', load)
  },
  setPerformancePeriod (state: IState, period: PerformancePeriod): void {
    Vue.set(state, 'performancePeriod', period)
  }
}

const getters = {
  getLoadingInsertionOrder (state: IState): boolean {
    return state.loadingInsertionOrder
  },
  getIoBrief (state: IState): IoBrief<DspInstruction> {
    return state.insertionOrder
  },
  getOverview (state: IState): OverviewData {
    return state.overview
  },
  getLoadingOverview (state: IState): boolean {
    return state.loadingOverview
  },
  getPerformance (state: IState): PerformanceData {
    return state.performance
  },
  getLoadingPerformance (state: IState): boolean {
    return state.loadingPerformance
  },
  getPerformancePeriod (state: IState): PerformancePeriod {
    return state.performancePeriod
  },
  getKpiFromPeriod (state: IState): KpiInterfaceWithType {
    switch (state.performancePeriod) {
      case 'day':
        return state.performance?.kpi_interfaces?.yesterday
      case 'week':
        return state.performance?.kpi_interfaces?.last_7_days
      case 'flight':
        return state.performance?.kpi_interfaces?.flight
      default:
        return state.performance?.kpi_interfaces?.yesterday
    }
  },
  getKpiFromPreviousPeriod (state: IState): KpiInterfaceWithType {
    switch (state.performancePeriod) {
      case 'day':
        return state.performance?.kpi_interfaces?.yesterday_minus_1
      case 'week':
        return state.performance?.kpi_interfaces?.last_7_days_minus_1
      case 'flight':
        return state.performance?.kpi_interfaces?.flight_minus_1
      default:
        return state.performance?.kpi_interfaces?.yesterday_minus_1
    }
  }
}

export const ioPage = {
  state,
  actions,
  mutations,
  getters
}
