
import { defineComponent } from 'vue'
import IOPageHeader from '@/components/IOPage/IOPageHeader.vue'
import IOPageOverview from '@/components/IOPage/OverviewTab/IOPageOverview.vue'
import baseViewMixin from '@/mixins/baseViewMixin'
import { generalMixin } from '@/mixins/generalMixin'
import { DspInstruction, IoBrief } from '../../types/instruction_type'
import IOPagePerformance from '@/components/IOPage/PerformanceTab/IOPagePerformance.vue'
import store from '../../store/index.js'
import { overlay } from '../../store/modules/overlay.js'
import { ioPage } from '../../store/modules/ioPage'
import { OverviewData, PerformanceData } from '../../types/io_page_types'
import { surcoucheV2Store } from '../../store/modules/surcoucheV2Store'
import '@/styles/surcoucheV2.styles.css'

// load the store module dynamically only when needed
if (!store.state.overlay) store.registerModule('overlay', overlay)
if (!store.state.ioPage) store.registerModule('ioPage', ioPage)
if (!store.state.surcoucheV2Store) store.registerModule('surcoucheV2Store', surcoucheV2Store)
export default defineComponent({
  name: 'IOPage',
  components: { IOPagePerformance, IOPageOverview, IOPageHeader },
  mixins: [baseViewMixin, generalMixin],
  data () {
    return {
      dsp: this.$route.params.dsp,
      ioId: this.$route.params.external_id,
      openTab: 'tab-1'
    }
  },
  async mounted () {
    this.onMounted()
    const data = {
      dsp: this.dsp,
      search: this.ioId
    }
    this.$store.commit('setLoadingInsertionOrder', true)
    this.$store.commit('setLoadingOverview', true)
    this.$store.commit('setLoadingPerformance', true)
    await Promise.all([
      this.$store.dispatch('getApiInsertionOrder', data),
      this.$store.dispatch('getApiOverview', data),
      this.$store.dispatch('getApiPerformance', data)
    ])
  },
  methods: {
    askSnackBar (message: string, status = 'success') {
      this.$emit('ask-snackbar', message, status)
    },
    openIoFromSearch (search: string) {
      // get route to change external_id to search then reload
      this.$router.replace({
        name: 'ioPage',
        params: { dsp: this.dsp, external_id: search }
      })
      location.reload()
    },
    redirectToSurcoucheV2 () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' }
      })
    }
  },
  computed: {
    loading (): boolean {
      return this.$store.getters.getLoadingInsertionOrder || this.$store.getters.getLoadingOverview
    },
    loadingOverview (): boolean {
      return this.$store.getters.getLoadingOverview
    },
    loadingPerformance (): boolean {
      return this.$store.getters.getLoadingPerformance
    },
    overviewNotFound (): boolean {
      return !this.loadingOverview && Object.keys(this.$store.getters.getOverview).length === 0
    }
  }
})
